import React, { useEffect, useState } from 'react'
import { Form, message } from 'antd'
import { useStoreState, useStoreActions } from 'easy-peasy'

import HaraSocialSF from './StoreFarmingForm'
import { Schema } from 'CampaignTypes'
import FormItem from './FormItem'
import ChannelSchemas from 'Constants/ChannelSchemas'
import FormItemZNS from './FormItemZNS'
import ZaloGroupForm from './ZaloGroupForm'
import Kpos from './Kpos'
import AppPushChanel from './AppPush'
import TetegramForm from './TelegramForm'

export default function ContentForm() {
  const { selected_placement, content } = useStoreState((state) => state.campaignModule)
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const [form] = Form.useForm()
  const [channelSchemas, setChannelSchemas] = useState(ChannelSchemas)
  useEffect(() => {
    selected_placement.code == 'sms' && content?.message_content?.includes('{{coupon_code}}')
      ? setChannelSchemas({
        ...channelSchemas,
        sms: [
          {
            name: 'message_content',
            type: 'dynamic_content',
            label: 'Content',
            isRequired: true,
          },
          {
            name: 'coupon_code_id',
            type: 'string',
            label: 'Coupon Campaign ID (Optional)',
            placeholder: 'Enter coupon campaign ID (from KFM Admin)',
            isRequired: true,
          },
        ],
      })
      : setChannelSchemas({ ...channelSchemas })
  }, [content])

  useEffect(() => {
    setState({ key: 'isContentValidated', value: false })
  }, [])
  const onFinish = () => {
    message.success('Content is validated')
    setState({ key: 'isContentValidated', value: true })
  }
  if (!selected_placement)
    return (
      <div className="flex items-center justify-center w-full h-full text-center">
        Please select a placement
      </div>
    )

  const renderBody = () => {
    if (selected_placement.code === 'hrs_store_farming') return <HaraSocialSF></HaraSocialSF>
    if (selected_placement.code === 'zalo_group') return <ZaloGroupForm />
    if (selected_placement.code === 'telegram_bot') return <TetegramForm />
    if (selected_placement?.code === 'kpos') return <Kpos></Kpos>
    if (selected_placement?.code === 'ol_app') return <AppPushChanel />
    if (selected_placement?.code === 'zns_by_template_id') {
      return <FormItemZNS />
    } else
      return (
        <Form
          layout="vertical"
          name="ContentForm"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 24 }}
          requiredMark
          onFinish={onFinish}
          initialValues={content}
          scrollToFirstError
          form={form}
        >
          {channelSchemas[selected_placement?.code]?.map((schema: Schema) => (
            <Form.Item
              key={schema.name}
              label={schema.label}
              tooltip={schema.tooltip}
              className={schema.className || ''}
              name={schema.name}
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues[schema.name] !== currentValues[schema.name]) return true
                return false
              }}
              rules={[
                ...(schema.isRequired
                  ? [
                    {
                      required: schema.isRequired,
                      message: `${schema.label} is required`,
                    },
                  ]
                  : []),
                ...(schema.validatePattern
                  ? [
                    {
                      pattern: schema.validatePattern,
                      message: `${schema.label} doesn't match the pattern`,
                    },
                  ]
                  : []),
              ]}
              initialValue={schema.defaultValue}
            >
              <FormItem
                name={schema?.name}
                type={schema?.type}
                value={form.getFieldValue(content && content[schema?.name])}
                options={schema.options}
                placeholder={schema.placeholder || ''}
                onChange={(name, value) => {
                  form.setFieldsValue({ [name]: value })
                  setState({ key: 'isContentValidated', value: false })
                  setState({ key: 'content', value: { ...content, [name]: value } })
                }}
              />
            </Form.Item>
          ))}
        </Form>
      )
  }
  return (
    <div>
      <div className="pb-6 text-lg font-semibold">{selected_placement?.name}</div>
      {renderBody()}
    </div>
  )
}
